import React from 'react'
import { PageProps } from 'gatsby'
import { PageLayout } from '@/components/pageLayout'
import Grid from '@material-ui/core/Grid'
import { Container } from '@/components/container'
import styled from 'styled-components'
import { NewsLetter } from '@/components/newsLetter'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import { ContactUsHeader } from '@/components/contactUsHeader'

const SectionWrapper = styled.div`
  padding: 32px 8px;
  border-radius: 8px;
`

const Spacer = styled.div`
  height: ${({ space = 64 }: { space?: number }) => `${space}px`};
`
const Contact: React.FC<PageProps> = ({ path }) => {
  return (
    <PageLayout pathname={path}>
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer />
      </Helmet>
      <ContactUsHeader />
      <Spacer />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <SectionWrapper>
              <NewsLetter />
            </SectionWrapper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SectionWrapper>
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                transition={{
                  delay: 1,
                  duration: 1,
                }}
                animate={{ opacity: 1, x: 0 }}
                className="elfsight-app-734deaf1-6971-4931-8e57-151bbcb05e76"
              />
            </SectionWrapper>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  )
}
export default Contact
