/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { colors } from '@/theme'
import Grid from '@material-ui/core/Grid'
import { StaticImage } from 'gatsby-plugin-image'
import { ReverseRadius } from './reverseRadius'

const Root = styled(motion.div)`
  position: relative;
  min-height: 500px;
`
const BG = styled(motion.div)`
  background-color: ${colors.primary};
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
`
const ImageContainer = styled.div`
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 0;
`

export const ContactUsHeader: React.FunctionComponent = () => {
  return (
    <Root>
      <BG
        initial={{
          height: `0%`,
          borderRadius: `0 0 100% 100%`,
        }}
        transition={{
          duration: 1.5,
          ease: `easeOut`,
        }}
        animate={{
          height: `100%`,
          opacity: 1,
          borderRadius: `0%`,
        }}
      />
      <div
        style={{ position: `absolute`, top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <Grid container style={{ height: `100%` }}>
          <Grid item xs={12}>
            <ReverseRadius padding="100px" style={{ margin: `0 auto` }}>
              <StaticImage
                loading="eager"
                placeholder="blurred"
                alt="devices"
                src="../img/undraw/undraw_contact_us_15o2.svg"
                width={1096}
                height={575}
              />
            </ReverseRadius>
          </Grid>
        </Grid>
      </div>
    </Root>
  )
}
